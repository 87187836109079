import { AfterViewChecked, Component, ElementRef, HostListener, Input, OnChanges, OnInit, Output, ViewChild, EventEmitter } from '@angular/core';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';
import { DataService } from '../../services/data-services/data-service';

@Component({
  selector: 'app-banner',
  templateUrl: './banner.component.html',
  styleUrls: ['./banner.component.css']
})
export class BannerComponent implements OnInit, OnChanges, AfterViewChecked {

	actualVideo: string = '';
	
	dateOfRace: string = '';
	
	volume =  false;
	
	eventData: any;
	emotionalVideo: any;
	
	rankingListLink: string = '';
	backArrow: string = '';
	url: string;
	urlDisplay: string;
	isLinkCopied = false;
	isscrollerVisible = true;
	idMediaLoaderVisible = true;
	showSuccessMsg:boolean = false;
	isPublishFeatureEnabled = environment.featureTogglePublish;

	@Input()
	data: any

	@Input()
	raceData: any;

	@Input()
	originalRaceInfo: any;

	@Input()
	trackInfo: any;

	@Input()
	isbannerLoaded: boolean = false;

	@Input()
	translatedArray: any = [];
	

	@Output()
	loaderVisibility = new EventEmitter();
	
	@ViewChild('bannerVideo') bannerVideo: ElementRef | null = null;
	
	
	@ViewChild('currentURL') currentURL: ElementRef | null = null;
	raceDate: any;
	raceTime: any;

	constructor(private router: Router, private dataService: DataService) { 
		this.url = window.location.href; 
		const url = this.url.split('//')[1].split('/').join(' / ');
		this.urlDisplay = 'https//' + url;
		
	}
	
	
	@HostListener('window:scroll', ['$event'])
	onScroll(e: any) {
		const element = document.querySelector('#current-url');
		if (element) {
			const position = element.getBoundingClientRect();
	
			//console.log(position, window.innerHeight);
			// checking whether fully visible
			if(position.top >= 0 && position.bottom <= window.innerHeight) {
				this.isscrollerVisible = false;
				//console.log('Element is fully visible in screen');
			}
		
			// checking for partial visibility
			if(position.top < window.innerHeight && position.bottom >= 0) {
				//console.log('Element is partially visible in screen');
			}
		}
		
	}
	
	ngOnChanges() {
		
	}
	
	ngAfterViewChecked() {
		if (this.bannerVideo) {
			if (this.bannerVideo.nativeElement) {			
				const video = this.bannerVideo.nativeElement;
				
				video.loadstart = (event: any) => {
					this.idMediaLoaderVisible = true;
				}
				video.oncanplay = (event: any) => {
					console.log('can play', event);
					video.play();
					this.idMediaLoaderVisible = false;
				};
			}
		}		
	}


	ngOnInit() {	
		this.emotionalVideo = this.data.videoAssetsInfo.find((media: any) => media.type ==="EMOTIONAL_VIDEO");
		let raceTimeData = new Date(this.data?.raceInfo.startTime);
		this.raceTime = raceTimeData.toLocaleTimeString([], {hour: '2-digit', minute:'2-digit'});
		const longEnUSFormatter = new Intl.DateTimeFormat('en-US', {
			year:  'numeric',
			month: 'long',
			day:   'numeric',
		});
		this.raceDate = longEnUSFormatter.format(raceTimeData);

		this.backArrow = '../../assets/images/back-arrow.svg',
		//this.rankingListLink = environment.serverUrl + 'ranking-list/list/' + this.originalRaceInfo.eventId;
		this.rankingListLink = this.data.eventInfo.leaderboardUrl?this.data.eventInfo.leaderboardUrl:this.data.exerciseInfo.leaderboardUrl
		
		// this.getEventName(this.originalRaceInfo.eventId);
		
		const element = document.querySelector('#current-url');
		if (element) {
			const position = element.getBoundingClientRect();
			if(position.top >= 0 && position.bottom <= window.innerHeight) {
				this.isscrollerVisible = false;
				console.log('Element is fully visible in screen');
			}
		}
		
		
		//this.dateOfRace = this.timeConverter(this.originalRaceInfo.lapdetails[0].starttime);

		//this.dateOfRace = this.data.raceInfo.startTime;		
		
		
	}

	scrollTo(className: string): void {
		const elementList = document.querySelectorAll('.' + className);
		const element = elementList[0] as HTMLElement;
		element.scrollIntoView({ behavior: 'smooth' });
	}
	
	timeConverter(UNIX_timestamp: any){
	  const a = new Date(UNIX_timestamp * 1000);
	  const months = ['Jan','Feb','Mar','Apr','May','Jun','Jul','Aug','Sep','Oct','Nov','Dec'];
	  const year = a.getFullYear();
	  const month = months[a.getMonth()];
	  const date = a.getDate();
	  const hour = (a.getHours() > 12) ? a.getHours()%12 : a.getHours();
	  const min = a.getMinutes() ;
	  const sec = a.getSeconds();
      const unit = (a.getHours() > 12) ? 'PM':'AM';
	  const time = date + ' ' + month + ' ' + year + ' ' + hour + ':' + min + ' ' + unit;
	  
	  return time;
	}
	
	controlScreen() {
		//this.volume = !this.volume;		
		const videoElement = document.getElementById('race-video');
		
		if (videoElement) {
			if (videoElement.requestFullscreen) {
				videoElement.requestFullscreen();
			}
		}		
	}
	
	copyText(val: string) {
		
	  let selBox = document.createElement('textarea');
	    selBox.style.position = 'fixed';
	    selBox.style.left = '0';
	    selBox.style.top = '0';
	    selBox.style.opacity = '0';
	    selBox.value = val;
	    document.body.appendChild(selBox);
	    selBox.focus();
	    selBox.select();
	    document.execCommand('copy');
	    document.body.removeChild(selBox);
		this.isLinkCopied= true;
	  }

	// getEventName(eventId: string) {
	// 	this.dataService.getEventData(eventId).subscribe((data: any) => {
	// 		this.eventData = data;
	// 		console.log(this.eventData.name);
	// 	})
	// }

	shareRace() {
		this.loaderVisibility.emit(true);
		this.dataService.publishRace(this.data.raceInfo.raceId).subscribe((data: any) => {
			this.data.raceInfo.isShared = true;
			this.showSuccessMsg = true;
			this.loaderVisibility.emit(false);
			setTimeout(() => {
				this.showSuccessMsg = false
			}, 8000)
		},
		(error: any ) => {			
			this.loaderVisibility.emit(false);
		}
		)
	}

}
