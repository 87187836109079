import { Component, ElementRef, EventEmitter, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import * as L from "leaflet";
import "leaflet.markercluster";
import { Map, TileLayer } from 'leaflet';
import { AppServicesService } from 'src/app/services/app-services.service';
import { environment } from 'src/environments/environment';
import { TourDbService } from './landingpage.db';
import { Location } from "@angular/common";
import { EmotionTourSummaryComponent } from '../emotion-tour-summary/emotion-tour-summary.component';
import { Title, Meta } from '@angular/platform-browser';
import { TranslationService } from 'src/app/services/translation.service';
import { LanguageService } from 'src/app/services/language.service';
import { EALL_TRACKS, MapViewResponse, OffRoadTourListEntity, SearchResponse } from '../interfaces/map-view.interface';
import { ToastService, ToastType } from 'src/app/services/toast.service';
declare const require: any
const polyline = require("google-polyline");
import { EncryptionService } from 'src/app/services/encryption.service';


@Component({
  selector: 'app-landing-page',
  templateUrl: './landing-page.component.html',
  styleUrls: ['./landing-page.component.scss']
})
export class LandingPageComponent implements OnInit, OnDestroy {

  @Output() public sendSelected = new EventEmitter<Boolean>();
  @Output() public showTour = new EventEmitter<any>();


  mapInstance: any;
  tileLayer: any;
  apiKey = environment.apikey;
  coordinate = [48, 9];
  currentStep: any;
  access_Token: any;
  emotionTourList: any;
  emotionTourIcon: any;
  tourMarker: any;
  highlightedIcon: any;
  zoomLevel: any;
  mapCoordinates: any;
  previousZoom: any;
  polylineDraw: any;
  radius: any;
  zoom: number = 5;
  trackGuid: any;
  track: any;
  trackData: any;
  title: any;
  owner: any;
  createdDate: any;
  trackLength: any;
  emotionTourData: any;
  AnnotationsData: any;
  showDetails = false;
  guid: any;
  loadAnnotationsFlag: boolean = false;
  summaryComponentInstance!: EmotionTourSummaryComponent;
  initialize = false;
  navFromLocation = false;
  isUserLoggedIn: any;
  routerUrls: any = [];
  isUser: any;
  circuitIcon: any;
  circuitsList: any;
  passedList: any;
  totalList: any;
  emotiontourMarker: any;
  circuittourMarker: any;
  circuitHighlightIcon: any;
  selectedFilter: any = EALL_TRACKS.ALL;
  showNotFound: any = false;
  selectedCoord: any;
  showListArea: any = true;
  showCircuitDetails = false;
  hideLandingPage: any = true;
  circuitData: any;
  circuitCoordinates: any;
  circuitimageData: any;
  navFromLanding: boolean = true;
  trackSlides: any;
  public spiderfyMarkers: any = [];
  completeList: any;
  resultCount:any;
  currentSearchTerm: string = "";
  mapInitialCoordinates: any = [52.520007, 13.404954]; 
  position: any = {
    coords: {
      latitude: 52.520007,
      longitude: 13.404954
    }
  };
  translationData = [];
  languageId: string|null;
  language = '';
  isPageNotFound = false;
  offRoadTourList: any[] = [];
  myTracksList:any[] = [];
  searchMode:boolean = false;
  filterMode:boolean = false;
  enableTourLines = environment.enableTourLines;
  totalElements: any;

  // Feature flags
  public _enableOffTour: boolean = false;
  public _enableMyTracks:boolean = false;
  public _enablefavourite:boolean = false;
  public _enableCircuits  : boolean = false;
  public _enableEmotionTour: boolean = false;
  public _setAccurateFade  : boolean = false;
  public _enableTourLines  : boolean = false;
  public _enable3DMap: boolean = false;
  public _enableDraw: boolean = false;
  public _enableTrackList:boolean = true;
  enableBlobDownload: boolean = true;

  constructor(private appservice: AppServicesService,
    private location: Location,
    private router: Router,
    private route: ActivatedRoute,
    private toastService: ToastService,
    private db: TourDbService, private titleService: Title, private metaService: Meta,
    private translationService: TranslationService, private languageService: LanguageService,
    private decryptionService : EncryptionService) {
    let access_token: any;
    
    this.languageId = this.route.snapshot.paramMap.get('languageId');
    if (this.languageId && environment.supported_language.includes(this.languageId)) {
      this.isPageNotFound = false
    } else {
      this.isPageNotFound = true;
    }

    this.isUser = localStorage.getItem('userLoggedIn');
      this.access_Token = localStorage.getItem('access_token');
      this.isUserLoggedIn = localStorage.getItem('isUserLoggedIn');

    this.language = this.languageService.getLanguage();

    this.db.openDb();
  }
  @ViewChild("mapElement") el!: ElementRef<any>;

  setSEOData(title: string, description: string) {
    this.titleService.setTitle(title);
    this.metaService.updateTag({ name: 'description', content: description });
  }

  ngOnInit(): void {
    localStorage.setItem('filter', 'ALL');
    localStorage.removeItem('searchTerm')
    this.translationService.getTranslationDataAsObservable().subscribe(
      (translationData: any) => {
        const locationFeatureFlags = this.translationService.featureFlags;
        this.enableBlobDownload = locationFeatureFlags.general.enableBlobDownload;
        this._enableMyTracks = locationFeatureFlags.landingPage.enableMyTracks;
        this._enablefavourite = locationFeatureFlags.landingPage.enableFavourite;
        this._enableOffTour = locationFeatureFlags.landingPage.enableOffTour;
        this._enableCircuits = locationFeatureFlags.landingPage.enableCircuits;
        this._enableEmotionTour= locationFeatureFlags.landingPage.enableEmotionTour;
        this._setAccurateFade  = locationFeatureFlags.landingPage.setAccurateFade;
        this._enableTourLines = locationFeatureFlags.landingPage.enableTourLines;
        this._enable3DMap = locationFeatureFlags.landingPage.enable3DMap;
        this._enableDraw = locationFeatureFlags.landingPage.enableDraw;
        this._enableTrackList = locationFeatureFlags.landingPage.enableTrackList
        this.translationData = translationData;
      }
    )
    this.apiKey = environment.apikey;
    this.showNotFound = false;

    this.appservice.getAllToursFromExpress().subscribe({
      next: (data: any) => {
        console.log('decrypt data',this.decryptionService.decrypt(data.data))
      }
    })

    this.getAnnotations();
        this.db.getTour('listData').then((list: any) => {
          if(list){
            this.completeList = list.data;
            this.passedList = list.data;
          }
        });
    this.getListData();

    this.highlightedIcon = L.icon({
      iconUrl: 'assets/images/default.png',
      iconSize: [42, 48],
      iconAnchor: [21, 46],
    });
    let noiseSvg = `<svg width="50" height="47" viewBox="0 0 61 64" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
      <defs>
          <filter x="-12.8%" y="-7.4%" width="125.6%" height="118.5%" filterUnits="objectBoundingBox" id="amjux2wi2a">
              <feOffset dy="1" in="SourceAlpha" result="shadowOffsetOuter1"/>
              <feGaussianBlur stdDeviation="1.5" in="shadowOffsetOuter1" result="shadowBlurOuter1"/>
              <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.2 0" in="shadowBlurOuter1"/>
          </filter>
          <path d="M25.5 51c-1.119-2.237-2.76-4.21-4.552-6.16l-.99-1.064-1.859-1.98-.647-.707-.64-.716-.315-.364-.623-.737C12.903 35.668 10.5 31.475 10.5 25.5 10.5 14.73 19.23 6 30 6l.46.005C41.017 6.25 49.5 14.885 49.5 25.5c0 5.975-2.403 10.168-5.374 13.772l-.623.737c-.104.122-.21.243-.316.364l-.64.716-.646.706-2.357 2.512C37.564 46.434 35.72 48.56 34.5 51a33.923 33.923 0 0 0-1.67 3.938l-.247.722-.216.666-.447 1.424-.158.472c-.392 1.111-.77 1.702-1.591 1.771L30 60c-.884 0-1.291-.52-1.683-1.563l-.157-.444-.08-.243-.447-1.424-.216-.666-.246-.722A33.923 33.923 0 0 0 25.5 51z" id="pwulttvzkb"/>
      </defs>
      <g fill="none" fill-rule="evenodd">
          <path d="M.604 0h60v60h-60z"/>
          <use filter="url(#amjux2wi2a)" xlink:href="#pwulttvzkb" fill-rule="nonzero" fill="#000" transform="translate(.604)"/>
          <path d="M26.104 51c-1.118-2.237-2.76-4.21-4.552-6.16l-.99-1.064-1.858-1.98-.647-.707-.64-.716-.316-.364-.623-.737c-2.97-3.604-5.374-7.797-5.374-13.772 0-10.77 8.73-19.5 19.5-19.5l.46.005c10.557.245 19.04 8.88 19.04 19.495 0 5.975-2.403 10.168-5.374 13.772l-.622.737-.316.364-.64.716-.647.706-2.357 2.512c-1.98 2.127-3.824 4.253-5.044 6.693a33.923 33.923 0 0 0-1.67 3.938l-.247.722-.216.666-.447 1.424-.158.472c-.392 1.111-.77 1.702-1.591 1.771l-.17.007c-.884 0-1.292-.52-1.684-1.563l-.157-.444-.08-.243-.447-1.424-.215-.666-.247-.722A33.923 33.923 0 0 0 26.105 51z" fill="#B21E23"/>
          <path d="M30.598 6.25c5.421.123 10.222 2.3 13.674 5.752A18.997 18.997 0 0 1 49.854 25.5c0 5.907-2.38 10.05-5.315 13.61l-.619.734-.953 1.076-.645.704-2.357 2.513c-1.998 2.145-3.854 4.29-5.084 6.751a34.176 34.176 0 0 0-1.684 3.969l-.248.726-.217.669-.444 1.416c-.27.779-.493 1.31-.757 1.647-.21.27-.447.399-.768.428-.418.001-.72-.088-.955-.284-.28-.235-.464-.608-.651-1.104l-.682-2.106-.217-.67-.248-.725a34.17 34.17 0 0 0-1.682-3.966c-1.129-2.257-2.784-4.249-4.593-6.22l-.99-1.063-1.857-1.979-.645-.703-.636-.713-.936-1.097c-2.937-3.563-5.317-7.706-5.317-13.613a19.19 19.19 0 0 1 5.638-13.612A19.187 19.187 0 0 1 30.598 6.25z" stroke="#FFF" stroke-width=".5"/>
          <path d="M18.604 13.5h24v24h-24z"/>
          <path d="m34.993 18.496.154.15 1.262 1.299.761-.748 1.315 1.337-2.942 2.892-1.315-1.337.71-.698-1.27-1.306a1.72 1.72 0 0 0-2.252-.188l-.124.101-7.818 6.945a2.397 2.397 0 0 0-.332 3.222l.111.138.114.126c.823.855 2.148.98 3.115.3l.143-.107 3.036-2.483a3.785 3.785 0 0 1 4.177-.41l.19.108.649.396c.89.543 2.023.435 2.794-.245l.132-.126.31-.32a2.218 2.218 0 0 0 .118-2.96l-.12-.132-.23-.236 1.473-1.448.238.245a4.283 4.283 0 0 1 .159 5.799l-.156.17-.31.32a4.427 4.427 0 0 1-5.292.806l-.19-.11-.65-.396a1.72 1.72 0 0 0-1.86.044l-.125.093-3.036 2.483a4.473 4.473 0 0 1-6.062-.368l-.152-.167-.127-.152a4.462 4.462 0 0 1 .344-5.977l.168-.157 7.818-6.944a3.785 3.785 0 0 1 5.072.04z" fill="#FFF"/>
      </g>
  </svg>
  `;
    this.circuitHighlightIcon = L.divIcon({
      html: noiseSvg,
      className: "mynoise",
      iconAnchor: [21, 48],
      iconSize: [42, 48],
    });

    this.circuitIcon = L.icon({
      iconUrl: 'assets/images/pointer_ct.svg',
      iconSize: [24, 24],
      iconAnchor: [12, 12],
    });

    this.setSEOData('Explore','landing page of the application')
  }

  ngAfterViewInit() {
    this.setUpLeaflet();

    if(navigator?.geolocation) {                        // Location access dialog logic 
      navigator?.geolocation.getCurrentPosition(
      (position: any) => {
        this.panningMapLocation(position);
      },
      (error: any) => {
        if (error?.code == error?.PERMISSION_DENIED) {
          this.panningMapLocation();
        }  
      });  
    }
  }

  private panningMapLocation(position?: any) {            // Panning the map to the location user grant permission 
    let coordinates = (position)? [position?.coords?.latitude, position?.coords?.longitude]: [this.position?.coords?.latitude, this.position?.coords?.longitude];
    this.mapInstance.setView(coordinates, this.zoom, { animate: false });
    this.getAnnotations();
  }

  decrypt(value: any): any {
    const key = 'secret key 123'; //SECRET KEY FOR ENCRYPTION
    const decrypted = CryptoJS.AES.decrypt(value, key).toString(CryptoJS.enc.Utf8);
    return JSON.parse(decrypted);
  }

  setUpLeaflet() {
    this.mapInstance = new Map(this.el?.nativeElement, {
      zoomControl: true,
      zoom: this.zoom,
      worldCopyJump: true,
      zoomSnap: 0,
      wheelDebounceTime: 50,
      center: [this.position?.coords?.latitude, this.position?.coords?.longitude],
      maxBounds: new L.LatLngBounds([
        [-90, -180],
        [90, 180],
      ]),
    });

    this.mapInstance.zoomControl.setPosition("bottomright");

    // create + add tile layer to map
    this.tileLayer = new TileLayer(
      'https://{s}.basemaps.cartocdn.com/dark_all/{z}/{x}/{y}{r}.png',
      {
        maxZoom: 19,
        minZoom: 2,
        attribution: "",
      }
    ).addTo(this.mapInstance);

    this.previousZoom = this.mapInstance.getZoom()


    this.mapInstance.on('zoomend', (e: any) => {
      this.getMapRadius();
      this.zoomLevel = e.target._zoom
      this.mapCoordinates = this.mapInstance.getCenter();
      this.getzoomLevelData();
      // this.db.getTour(this.zoomLevel + this.mapCoordinates).then((data: any) => {
      //   if(data) {
      //     // this.drawPolylinesOnMap(data.trackData, '#344e94');
      //   } else {
      //     // this.getzoomLevelData();
      //   }
      // });
    });
    this.mapInstance.on('moveend', (e: any) => {
      this.getMapRadius();
      this.zoomLevel = e.target._zoom
      this.mapCoordinates = this.mapInstance.getCenter()
      this.getzoomLevelData();
    });
  }

  goToTour(event: any) {
    if ((event.annotationsCount || event.type === 'EMOTION_TOUR') && this._enableEmotionTour) {
      let coordinate = event.coordinates.split(",");
      this.mapInstance.flyTo([coordinate[0], coordinate[1]], 12, { duration: 3 });
      this.getAnnotationsData(event.guid);
      let tourName = event.name
        .replace(/\s+/g, "-")
        .replace(/[^\w\s]/gi, "-");
      let layoutURL = `/${this.language}/explore/emotion-tour/${tourName}-${event.guid}`;
      this.location.go(layoutURL)
    }

    else if ((event.hasOwnProperty('layouts') || event.type === EALL_TRACKS.CIRCUIT) && this._enableCircuits) {
      let coordinate = event.coordinates.split(",");
      this.mapInstance.flyTo([coordinate[0], coordinate[1]], 10, { duration: 2 });
      this.fetchCircuitDetails(event.guid);
      setTimeout(() => {
        this.showCircuitDetails = true;
        this.hideLandingPage = false;
      }, 2000);
      let layoutURL = `/${this.language}/explore/circuit/${event.guid}`;
      this.location.go(layoutURL)
    }

  }


  getMapRadius() {
    let topcoordinates = this.mapInstance.getBounds().getNorthEast();
    let mapBoundNorthEast = this.mapInstance.getBounds().getNorthEast();
    let mapBoundNorthWest = this.mapInstance.getBounds().getNorthWest();
    // let topDistance = mapBoundNorthEast.distanceTo(mapBoundNorthWest);
    topcoordinates.lat =( mapBoundNorthEast.lat + mapBoundNorthWest.lat)/2;
    topcoordinates.lng =( mapBoundNorthEast.lng + mapBoundNorthWest.lng)/2
    let mapDistance = topcoordinates.distanceTo(this.mapInstance.getCenter());
    this.radius = parseInt(mapDistance);
  }


  clearMap() {
    this.mapInstance.eachLayer((layer: any) => {
      if (!layer["_url"]) {
        this.mapInstance.removeLayer(layer);
      }
    });
  }

  public getAnnotations(): void {
    this.appservice
      .getAllEmotionTour()
      .subscribe({
        next: (result: any) => {
          let track = this.decryptionService.decrypt(result.data);
          this._enableEmotionTour? this.emotionTourList = track.emotionTourList : this.emotionTourList =[]
          this._enableCircuits? this.circuitsList = track.circuitInfoList : this.circuitsList=[]
          this._enableOffTour ? this.offRoadTourList = track.offRoadTourList ?? [] : this.offRoadTourList = []
          // this._enableMyTracks ? this.myTracksList = track.myTrackList ?? [] : 
          this.totalList = [...this.emotionTourList, ...this.circuitsList, ...this.offRoadTourList];       
          if (this._enableEmotionTour) { this.startClustering(this.mapInstance, this.emotionTourList, EALL_TRACKS.EMOTION_TOUR); }
          if (this._enableCircuits) { this.startClustering(this.mapInstance, this.circuitsList, EALL_TRACKS.CIRCUIT); }
          if (this._enableOffTour) { this.startClustering(this.mapInstance, this.offRoadTourList, EALL_TRACKS.OFF_ROAD); }
        },
      });
      // this.getToursAtZoom();
  }


  getzoomLevelData() {
    if(!this.searchMode && !this.filterMode && this.enableTourLines){
      this.zoomLevel = Math.round(this.zoomLevel);
    this.appservice
      .getMapCircuitList(this.zoomLevel, this.mapCoordinates, this.radius)
      .subscribe({
        next: (data: any) => {
          let track = this.decryptionService.decrypt(data.data);
          console.log('track at zoom',track)      
          this._enableEmotionTour? this.emotionTourList = track?.emotionTourList : this.emotionTourList =[]
          this._enableCircuits? this.circuitsList = track?.circuitInfoList : this.circuitsList=[]
          this._enableOffTour ? this.offRoadTourList = track?.offRoadTourList ?? [] : this.offRoadTourList = []
          this.totalList = [...this.emotionTourList, ...this.circuitsList, ...this.offRoadTourList];
          this.resultCount = this.totalList.length;
          let encodedTrackArray: any = []
          let encodedCircuitArray:any =[]
          track.emotionTourList.forEach((element: any) => {
            if (element.encodedPolyline && this._enableEmotionTour) {
              encodedTrackArray.push(element);
              track.emotionTourList.pop(element);
            }
          });
          track.circuitInfoList.forEach((element: any) => {
            if (element.encodedPolyline && this._enableCircuits) {
              encodedCircuitArray.push(element);
              track.circuitInfoList.pop(element);
            }
          });
          this.clearMap();
          if(encodedTrackArray.length > 0){
            this.drawPolylinesOnMap(encodedTrackArray, '#344e94', EALL_TRACKS.EMOTION_TOUR);
          }
          if(encodedCircuitArray.length > 0){
          this.drawPolylinesOnMap(encodedCircuitArray, '#b21e23', EALL_TRACKS.CIRCUIT);
          }

          if (this._enableEmotionTour) {this.startClustering(this.mapInstance, track.emotionTourList,EALL_TRACKS.EMOTION_TOUR);}
          if (this._enableCircuits) {this.startClustering(this.mapInstance, track.circuitInfoList,  EALL_TRACKS.CIRCUIT);}
          if ( this._enableOffTour) {this.startClustering(this.mapInstance, track.offRoadTourList, EALL_TRACKS.OFF_ROAD)};
          let cacheZoomData = {
            trackData: encodedTrackArray,
            trackId: this.zoomLevel + this.mapCoordinates
          }
          this.db.addTour(cacheZoomData);

        },
      });
    }    
  }

  drawPolylinesOnMap(data: any, color:any, type:any) {
    this.clearMap();
    // this.makeCapitalCircleMarkers(this.emotionTourList, 'emotionTour');
    data?.forEach((element: any) => {
      // this.removeHightlightIcon(element.guid);
      let polylineData = polyline.decode(element.encodedPolyline)
      this.polylineDraw = L.polyline(polylineData, { color: color, fillColor: color,
      fill: false, fillOpacity: 0.1 }).addTo(this.mapInstance);
      this.polylineDraw.guid = element.guid;
      this.polylineDraw.name = element.name;
      this.polylineDraw.coordinates = element.coordinates;
      this.polylineDraw.type = type;

      this.polylineDraw.on('click', (e: any) => {
        this.goToTour(e.target);
      })
      this.polylineDraw.on('mouseover', (e: any) => {
        let layer = e.target;
        layer.setStyle({
          color: 'yellow',
          opacity: 1,
          weight: 5
        });
        let customPopup = "<h1>" + element.name + "</h1>";
        // specify popup options
        let customOptions = {
          maxWidth: 200,
          className: "custompopup",
        };
        layer.bindPopup(customPopup, customOptions).openPopup();
      });
      this.polylineDraw.on('mouseout', (e: any) => {
        let layer = e.target;
        if(this.polylineDraw.type === EALL_TRACKS.CIRCUIT){
          layer.setStyle({
            color: '#b21e23',
            opacity: 1,
            weight: 5
          });
        } else{
          layer.setStyle({
            color: '#344e94',
            opacity: 1,
            weight: 5
          });
        }        
        // layer.target.closePopup();
      });
    });
  }

  public getListData(): void {
    let circuits: any =[];
    let emotiontour: any =[];
    let offroadList: OffRoadTourListEntity[] = [];
    this.appservice
      .gobalSearch(15, 0, '', this._enableCircuits, this._enableEmotionTour, this._enableOffTour)
      .subscribe({
        next: ((data: any) => {
          let res = this.decryptionService.decrypt(data.data)
          if (res) {
            this.totalElements = res.totalElements;
            res.locations.forEach((element: any) => {
              if (element.type === EALL_TRACKS.CIRCUIT || element.hasOwnProperty('layoutsAvailable')) {
                circuits.push(element)
              } else if (element.type === EALL_TRACKS.EMOTION_TOUR) {
                emotiontour.push(element)
              } else {
                offroadList.push(element)
              }
              this.passedList = [...emotiontour, ...circuits, ...offroadList];
              this.completeList = this.passedList;
              let tourInfo = {
                trackId: 'listData',
                data: this.completeList
              };
              this.db.getTour('listData').then((data: any) => {
                if(data){
                  this.db.updateTour('listData', tourInfo);
                } else {
                  this.db.addTour(tourInfo);
                }
              });
            });
          }
        }),
      });
  }

  makeCapitalCircleMarkers(data: any, type: any) {
    for (const tourItems of data) {
      const lat = tourItems.coordinates.split(",")[0];
      const lon = tourItems.coordinates.split(",")[1];
      let position: any = {
        'lat': lat,
        'lon': lon
      }
      this.emotionTourIcon = L.icon({
        iconUrl: 'assets/images/pointer_et.svg',
        iconSize: [24, 24],
        iconAnchor: [12, 12],
      });

      this.circuitIcon = L.icon({
        iconUrl: 'assets/images/pointer_ct.svg',
        iconSize: [24, 24],
        iconAnchor: [12, 12],
      });

      this.highlightedIcon = L.icon({
        iconUrl: 'assets/images/default.png',
        iconSize: [42, 48],
        iconAnchor: [21, 46],
      });

      let noiseSvg = `<svg width="50" height="47" viewBox="0 0 61 64" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
      <defs>
          <filter x="-12.8%" y="-7.4%" width="125.6%" height="118.5%" filterUnits="objectBoundingBox" id="amjux2wi2a">
              <feOffset dy="1" in="SourceAlpha" result="shadowOffsetOuter1"/>
              <feGaussianBlur stdDeviation="1.5" in="shadowOffsetOuter1" result="shadowBlurOuter1"/>
              <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.2 0" in="shadowBlurOuter1"/>
          </filter>
          <path d="M25.5 51c-1.119-2.237-2.76-4.21-4.552-6.16l-.99-1.064-1.859-1.98-.647-.707-.64-.716-.315-.364-.623-.737C12.903 35.668 10.5 31.475 10.5 25.5 10.5 14.73 19.23 6 30 6l.46.005C41.017 6.25 49.5 14.885 49.5 25.5c0 5.975-2.403 10.168-5.374 13.772l-.623.737c-.104.122-.21.243-.316.364l-.64.716-.646.706-2.357 2.512C37.564 46.434 35.72 48.56 34.5 51a33.923 33.923 0 0 0-1.67 3.938l-.247.722-.216.666-.447 1.424-.158.472c-.392 1.111-.77 1.702-1.591 1.771L30 60c-.884 0-1.291-.52-1.683-1.563l-.157-.444-.08-.243-.447-1.424-.216-.666-.246-.722A33.923 33.923 0 0 0 25.5 51z" id="pwulttvzkb"/>
      </defs>
      <g fill="none" fill-rule="evenodd">
          <path d="M.604 0h60v60h-60z"/>
          <use filter="url(#amjux2wi2a)" xlink:href="#pwulttvzkb" fill-rule="nonzero" fill="#000" transform="translate(.604)"/>
          <path d="M26.104 51c-1.118-2.237-2.76-4.21-4.552-6.16l-.99-1.064-1.858-1.98-.647-.707-.64-.716-.316-.364-.623-.737c-2.97-3.604-5.374-7.797-5.374-13.772 0-10.77 8.73-19.5 19.5-19.5l.46.005c10.557.245 19.04 8.88 19.04 19.495 0 5.975-2.403 10.168-5.374 13.772l-.622.737-.316.364-.64.716-.647.706-2.357 2.512c-1.98 2.127-3.824 4.253-5.044 6.693a33.923 33.923 0 0 0-1.67 3.938l-.247.722-.216.666-.447 1.424-.158.472c-.392 1.111-.77 1.702-1.591 1.771l-.17.007c-.884 0-1.292-.52-1.684-1.563l-.157-.444-.08-.243-.447-1.424-.215-.666-.247-.722A33.923 33.923 0 0 0 26.105 51z" fill="#B21E23"/>
          <path d="M30.598 6.25c5.421.123 10.222 2.3 13.674 5.752A18.997 18.997 0 0 1 49.854 25.5c0 5.907-2.38 10.05-5.315 13.61l-.619.734-.953 1.076-.645.704-2.357 2.513c-1.998 2.145-3.854 4.29-5.084 6.751a34.176 34.176 0 0 0-1.684 3.969l-.248.726-.217.669-.444 1.416c-.27.779-.493 1.31-.757 1.647-.21.27-.447.399-.768.428-.418.001-.72-.088-.955-.284-.28-.235-.464-.608-.651-1.104l-.682-2.106-.217-.67-.248-.725a34.17 34.17 0 0 0-1.682-3.966c-1.129-2.257-2.784-4.249-4.593-6.22l-.99-1.063-1.857-1.979-.645-.703-.636-.713-.936-1.097c-2.937-3.563-5.317-7.706-5.317-13.613a19.19 19.19 0 0 1 5.638-13.612A19.187 19.187 0 0 1 30.598 6.25z" stroke="#FFF" stroke-width=".5"/>
          <path d="M18.604 13.5h24v24h-24z"/>
          <path d="m34.993 18.496.154.15 1.262 1.299.761-.748 1.315 1.337-2.942 2.892-1.315-1.337.71-.698-1.27-1.306a1.72 1.72 0 0 0-2.252-.188l-.124.101-7.818 6.945a2.397 2.397 0 0 0-.332 3.222l.111.138.114.126c.823.855 2.148.98 3.115.3l.143-.107 3.036-2.483a3.785 3.785 0 0 1 4.177-.41l.19.108.649.396c.89.543 2.023.435 2.794-.245l.132-.126.31-.32a2.218 2.218 0 0 0 .118-2.96l-.12-.132-.23-.236 1.473-1.448.238.245a4.283 4.283 0 0 1 .159 5.799l-.156.17-.31.32a4.427 4.427 0 0 1-5.292.806l-.19-.11-.65-.396a1.72 1.72 0 0 0-1.86.044l-.125.093-3.036 2.483a4.473 4.473 0 0 1-6.062-.368l-.152-.167-.127-.152a4.462 4.462 0 0 1 .344-5.977l.168-.157 7.818-6.944a3.785 3.785 0 0 1 5.072.04z" fill="#FFF"/>
      </g>
    </svg>
    `;

      this.circuitHighlightIcon = L.divIcon({
        html: noiseSvg,
        className: "mynoise",
        iconAnchor: [21, 48],
        iconSize: [42, 48],
      });

      if (type === EALL_TRACKS.EMOTION_TOUR) {
        this.emotiontourMarker = L.marker(position, { icon: this.emotionTourIcon });
        this.emotiontourMarker.name = tourItems.guid
        this.emotiontourMarker.on('click', (e: any) => {
          let tourInfo = {
            type: 'EMOTION_TOUR',
            coordinates: tourItems.coordinates,
            guid: tourItems.guid,
            name: tourItems.name
          }
          this.goToTour(tourInfo);
        })
        this.mapInstance.addLayer(this.emotiontourMarker);
      }


      if (type === EALL_TRACKS.CIRCUIT) {
        this.circuittourMarker = L.marker(position, { icon: this.circuitIcon });
        this.circuittourMarker.name = tourItems.guid
        this.mapInstance.addLayer(this.circuittourMarker);
        this.circuittourMarker.on('click', (e: any) => {
          let tourInfo = {
            type: EALL_TRACKS.CIRCUIT,
            coordinates: tourItems.coordinates,
            guid: tourItems.guid,
            name: tourItems.name
          }
          this.goToTour(tourInfo);
        })
      }  
    }
  
  }


  addHightlightIcon(data: any, icon: any) {
    this.mapInstance.eachLayer((layer: any) => {
      if (layer.name === data) {
        // layer.setIcon(icon);
      }
    })
  }

  showMarker(event: any) {
    let passedData:any = {}
    this.selectedFilter = event;
    this.filterMode = true;
    passedData.filterTrigger = true;
    passedData.searchTrigger = false;
    passedData.data = event;
    this.updateDataOnMapAndList(passedData);

  }

  plotSelectedTour(coordinates: any) {
    let polylineData = coordinates
    let polylineDraw = L.polyline(polylineData, { color: '#344e94' }).addTo(this.mapInstance);
    this.mapInstance.fitBounds(polylineDraw.getBounds(), {
      paddingTopLeft: [200, 100],
      paddingBottomRight: [0, 0],
    });   

  }

  backClicked(event:any){
    this.showListArea = true;
    this.hideLandingPage = true;
    this.showDetails = false;
    this.initialize = false;
    this.loadAnnotationsFlag = false;
    let tourCover = document.getElementById('summaryPage');
    tourCover?.classList.remove('details-page-class');
  }

  getAnnotationsData(guid: any) {
    this.appservice
      .getClusterAnnotations(guid)
      .subscribe({
        next: (response: any) => {
          let track = this.decryptionService.decrypt(response.data)
          this.track = track;
          this.guid = guid;
          let newArray: any = []
          track.features[0]?.geometry?.coordinates.forEach((element: any) => {
            let swap = [element[1], element[0]]
            newArray.push(swap)
          });
          if (newArray.length > 0) {            
            this.showListArea = false;
            this.selectedCoord = newArray
            this.hideLandingPage = false;
            this.showDetails = true;
            this.addDisplayClassForSummary();
          }
          this.trackData = this.track.features.filter((item: any) => {
            if (item.properties) {
              if (item.properties.landmark_type?.toLowerCase() === 'route') {
                return item;
              }
            }
          });
          this.trackData = this.trackData[0];
          this.title = this.trackData.properties.name;
          this.owner = this.trackData.properties.provider;
          this.createdDate = new Date(
            this.trackData.properties.createdDate?.replace(/\s/, 'T')
          ).toLocaleDateString('de-DE', {
            year: 'numeric',
            month: '2-digit',
            day: '2-digit',
          });
          this.trackLength = this.trackData.properties.trackLength / 1000;
          this.track.features.filter((item: any) => {
            if (item.properties) {
              if (item.properties.landmark_type?.toLowerCase() === 'route') {
                if (
                  item.geometry.coordinates[0][0] ===
                  item.geometry.coordinates[
                  item.geometry.coordinates.length - 1
                  ][0] &&
                  item.geometry.coordinates[0][1] ===
                  item.geometry.coordinates[
                  item.geometry.coordinates.length - 1
                  ][1]
                ) {
                  this.track.isRoundTrip = true;
                }

                return item;
              }
            }
          });
          this.emotionTourData = this.track;
          this.AnnotationsData = this.track.features.filter((item: any) => {
            if (item.properties) {
              if (
                item.geometry.type !== 'Polygon' &&
                item.properties.landmark_type?.toLowerCase() !== 'route' &&
                item.properties.landmark_type?.toLowerCase() !== 'origin' &&
                item.properties.landmark_type?.toLowerCase() !== 'destination'
              ) {
                item.properties.route_position =
                  item.properties.route_position / 1000;
                if (item?.properties?.annotations?.length === 1) {
                  item.isSingleton = true;
                } else {
                  item.multiple = true;
                }
                return item;
              }
            }

          });
          this.AnnotationsData = this.AnnotationsData.sort(
            (a: any, b: any) =>
              a.properties.route_position - b.properties.route_position
          );
          this.trackData = this.track[0];
          this.loadAnnotationsFlag = true;

        },
        error: (error) => {
          if (error.error['error-code']) {
            this.toastService.show(ToastType.Error, 'CP_LM_TOAST_MSG_CLUSTER_NOT_FOUND', '8%', '40%');
            setTimeout(() => {
              this.toastService.hide();
            }, 4000);
            this.hideLandingPage = true;
            this.showDetails = false;
            this.loadAnnotationsFlag = false;
            this.location.go('/'+this.language+'/explore');
          }
        },
      });
  }

  fetchCircuitDetails(guid: any) {
    this.appservice
      .getCircuitDetailFromExpress(guid)
      .subscribe((result: any) => {
        let data = this.decryptionService.decrypt(result.data)
        this.circuitData = data;        
        this.showListArea = false;
        let coordinate = data.location.split(",");
        this.circuitCoordinates = [coordinate[0], coordinate[1]];
        this.addHightlightIcon(guid, this.circuitIcon);
        if(this.enableBlobDownload){
          this.downloadBlobImage()
        } else {
          this.circuitimageData = this.circuitData?.media?.image[0].originalUrl ?? "assets/locations/redcircuit.png"
        }
        this.updateTrackInfo();
      });
  }

  downloadBlobImage(url?: any) {
    if (this.circuitData.media) {
      this.appservice.getBlobImage(this.circuitData.media.image[0].id).subscribe({
        next: (blob: any) => {

          const reader = new FileReader();

          reader.readAsDataURL(blob);

          reader.onloadend = () => {
            this.circuitimageData = reader.result?.toString() ?? "assets/locations/redcircuit.png"
          };
        }
      });
    } else {
      this.circuitimageData = "../../../assets/images/explore_nature.png"
    }

  }

  updateTrackInfo() {
    this.trackSlides = this.circuitData.layouts?.map((item: any) => {
      if (typeof item.encodedGpsPoints == "string") {
        item.encodedGpsPoints = polyline.decode(item.encodedGpsPoints);
      }
      item.trackLength = item.trackLength / 1000;
      item.circuitGuid = this.guid;
      item.fill = "none";
      item.path = "#767676";
      item.updatedDate = new Date(item.updatedDate).toLocaleDateString(
        "de-DE",
        { year: "numeric", month: "2-digit", day: "2-digit" }
      );
      return item;
    });
    let firstElement ={
      name:'Show all',
      fill: 'none',
      path:'#767676',
      encodedGpsPoints:polyline.decode
      ("enl~FnravOcj@ooAbRknA|bAzaC}j@~[")
    }
    this.trackSlides.unshift(firstElement);
  }

  hideCircuitDetails(event: any) {
    this.hideLandingPage = true;
    this.showCircuitDetails = false;
    this.showListArea = true;
    this.mapInstance.setZoom(8)
    this.location.go('/'+this.language+'/explore');
  }


  public startClustering(map: any, data: any, type: any): void {
    // First we need to create an array of DataPoint objects,
    // for the ClusterProvider
    let clusterSvgTemplate: any;
    let dataPoints = data?.map((item: any) => {
      return item.coordinates.split(",");
    });
    let noiseSvg;
    let color: any;
    let myIcon: any;
    switch(type) {
      case EALL_TRACKS.CIRCUIT:
        color = "rgb(178, 30, 35)";
        myIcon = L.icon({
          iconUrl: 'assets/images/pointer_ct.svg',
          iconSize: [24, 24],
          iconAnchor: [12, 12],
        });
        break;
      case EALL_TRACKS.EMOTION_TOUR: 
      color = "rgb(1, 72, 128)";
      myIcon = L.icon({
        iconUrl: 'assets/images/pointer_et.svg',
        iconSize: [24, 24],
        iconAnchor: [12, 12],
      });
      break;
    case EALL_TRACKS.OFF_ROAD: 
      color = 'rgb(204, 131, 41)';
      myIcon = L.icon({
        iconUrl: 'assets/images/pointer_offroad.svg',
        iconSize: [16, 16],
        iconAnchor: [12, 12],
      });
      break;
    }
    let clusterGroup = L.markerClusterGroup({
      iconCreateFunction: function (cluster: any) {
        cluster.type = type;
        let markers = cluster.getAllChildMarkers();

        clusterSvgTemplate = `<svg xmlns="http://www.w3.org/2000/svg"  height="40px" width="40px" class="parent-svg">
       <circle cx="21px" cy="21px" r="17" fill="none" stroke="${color}" stroke-width="2" class="svg-icon"/><circle cx="21px" cy="21px" r="14" fill="${color}" class="svg-icon1"/>'
       '<text x="21" y="24" font-size="10px" font-family="MBCorpoSTextOffice-Regular"  fill="#FFF" text-anchor="middle">${markers.length}</text>'
       '</svg>`;
        let n = 0;
        return L.divIcon({
          html: clusterSvgTemplate,
          className: "mycluster",
          iconSize: L.point(40, 40),
        });
      },
      maxClusterRadius: 50,
      spiderfyOnMaxZoom: true,
      showCoverageOnHover: false,
      removeOutsideVisibleBounds: true,
      zoomToBoundsOnClick: true,
      animate: true,
    });
    clusterGroup.on("spiderfied unspiderfied", (event: any) => {
      this.spiderfyMarkers = event.markers;
    });
    let marker: any = [];
    dataPoints?.forEach((point: any, index: any) => {
      marker = L.marker(new L.LatLng(point[0], point[1]), {
        icon: myIcon,
      });
      marker.data = data[index];
      marker.data.viewType = type;
      marker.type = type;
      marker.name = data[index].guid;
      clusterGroup.addLayer(marker);
      marker.on("click", (e: any) => {
        let tourInfo;
        if (type === EALL_TRACKS.EMOTION_TOUR) {
          tourInfo = {
            type: 'EMOTION_TOUR',
            coordinates: e.target.data.coordinates,
            guid: e.target.data.guid,
            name: e.target.data.name
          }
        } else if(type === EALL_TRACKS.CIRCUIT) {
          tourInfo = {
            type: EALL_TRACKS.CIRCUIT,
            coordinates: e.target.data.coordinates,
            guid: e.target.data.guid,
            name: e.target.data.name
          } 
        } else {
          tourInfo = {
            type: EALL_TRACKS.OFF_ROAD,
          coordinates: e.target.data.coordinates,
          guid: e.target.data.guid,
          name: e.target.data.name
          }
        }

        this.goToTour(tourInfo);
      });
    });
    map.addLayer(clusterGroup);
  }

  filterDuplicatesFromList(list:any, key:any){
    return list.filter((obj:any, index:number) => {
      return index === list.findIndex((o:any) => obj[key] === o[key]);
    });
  }

  public updateDataOnMapAndList(data?: any): void {
    let circuits: any = [];
    let emotiontour: any = [];
    let offRoadsList: any[] = [];
    if (data.searchTrigger) {
      if (this.currentSearchTerm.length > 0) {
        this.mapInstance.setZoom(3)
        this.clearMap();
        data.data.completeList?.forEach((element: any) => {
          if ((element.type === EALL_TRACKS.CIRCUIT || element.hasOwnProperty('layoutsAvailable')) && this._enableCircuits) {
            circuits.push(element)
          } else if (element.type === EALL_TRACKS.EMOTION_TOUR && this._enableEmotionTour) {
            emotiontour.push(element)
          } else if (element.type === EALL_TRACKS.OFF_ROAD &&  this._enableOffTour){
            offRoadsList.push(element);
          }
        });
        if (circuits && this.selectedFilter === EALL_TRACKS.CIRCUIT && this._enableCircuits) {
          this.startClustering(this.mapInstance, circuits, EALL_TRACKS.CIRCUIT);
          this.passedList = circuits;
          this.passedList = this.filterDuplicatesFromList(this.passedList, 'guid');
          this.resultCount = this.passedList.length;
        } else if (emotiontour && this.selectedFilter === EALL_TRACKS.EMOTION_TOUR && this._enableEmotionTour) {
          this.startClustering(this.mapInstance, emotiontour, EALL_TRACKS.EMOTION_TOUR);
          this.passedList = emotiontour;
          this.passedList = this.filterDuplicatesFromList(this.passedList, 'guid');
          this.resultCount = this.passedList.length;
        } else if(this.selectedFilter === EALL_TRACKS.OFF_ROAD &&  this._enableOffTour) {
          this.startClustering(this.mapInstance, offRoadsList, EALL_TRACKS.OFF_ROAD);
          this.passedList = offRoadsList;
          this.passedList = this.filterDuplicatesFromList(this.passedList, 'guid');
          this.resultCount = this.passedList.length;
        } else if (emotiontour && circuits && this.selectedFilter === EALL_TRACKS.ALL) {
          if ( this._enableCircuits) {
            this.startClustering(this.mapInstance, circuits, EALL_TRACKS.CIRCUIT);
          }
          if ( this._enableEmotionTour) {
            this.startClustering(this.mapInstance, emotiontour, EALL_TRACKS.EMOTION_TOUR);
          }          
          if ( this._enableOffTour) {
            this.startClustering(this.mapInstance, offRoadsList, EALL_TRACKS.OFF_ROAD);
          }
          this.passedList = [...emotiontour, ...circuits, ...offRoadsList];
          this.passedList = this.filterDuplicatesFromList(this.passedList, 'guid')
          this.resultCount = this.passedList.length;
        }
      } else {
        let listCircuits: any = [];
        let listTours: any = [];
        let offRoadTours: any[] = [];
        this.searchMode = false;
        data.data.completeList?.forEach((element: any) => {
          if ((element.type === EALL_TRACKS.CIRCUIT || element.hasOwnProperty('layoutsAvailable'))&&this._enableCircuits) {
            listCircuits.push(element)
          } else if(element.type === EALL_TRACKS.EMOTION_TOUR && this._enableEmotionTour) {
            listTours.push(element)
          } else if (element.type === EALL_TRACKS.OFF_ROAD &&  this._enableOffTour) {
            offRoadTours.push(element)
          }
        });
        if ((listCircuits && this.selectedFilter === EALL_TRACKS.CIRCUIT) && this._enableCircuits) {
          this.passedList = listCircuits;
          this.passedList = this.filterDuplicatesFromList(this.passedList, 'guid');
          this.resultCount = this.circuitsList.length;
          this.clearMap();
          this.startClustering(this.mapInstance, this.circuitsList, EALL_TRACKS.CIRCUIT);
        } else if ((listTours && this.selectedFilter === EALL_TRACKS.EMOTION_TOUR) && this._enableEmotionTour) {
          this.passedList = listTours;
          this.passedList = this.filterDuplicatesFromList(this.passedList, 'guid');
          this.resultCount = this.emotionTourList.length;
          this.clearMap();
          this.startClustering(this.mapInstance, this.emotionTourList, EALL_TRACKS.EMOTION_TOUR);
        } else if (this.selectedFilter === EALL_TRACKS.OFF_ROAD && this._enableOffTour) {
          this.passedList = offRoadTours;
          this.passedList = this.filterDuplicatesFromList(this.passedList, 'guid');
          this.resultCount = this.passedList.length;
          this.clearMap();
          this.startClustering(this.mapInstance, this.offRoadTourList, EALL_TRACKS.OFF_ROAD);
        } else if (listTours && listCircuits && (this.selectedFilter === EALL_TRACKS.ALL || this.selectedFilter === EALL_TRACKS.FAVOURITE || this.selectedFilter === EALL_TRACKS.MY_TRACKS)) {
          this.clearMap();
          if ( this._enableCircuits) {
            this.startClustering(this.mapInstance, this.selectedFilter === EALL_TRACKS.ALL? this.circuitsList: listCircuits, EALL_TRACKS.CIRCUIT);
          }
          if ( this._enableEmotionTour) {
            this.startClustering(this.mapInstance,  this.selectedFilter === EALL_TRACKS.ALL?this.emotionTourList:listTours, EALL_TRACKS.EMOTION_TOUR);
          } 
          if (this._enableOffTour) {
            this.startClustering(this.mapInstance, this.selectedFilter === EALL_TRACKS.ALL?this.offRoadTourList:offRoadTours, EALL_TRACKS.OFF_ROAD);
          }
          this.passedList = data.data.completeList;
          this.passedList = this.filterDuplicatesFromList(this.passedList, 'guid')
          this.resultCount = this.emotionTourList?.length + this.circuitsList?.length;

        }
      }
    }

    if (data.filterTrigger) {
      switch (data.data) {
        case EALL_TRACKS.ALL:
          localStorage.setItem('filter', data.data);
          this.filterMode = false;
          if (this.currentSearchTerm.length == 0) {
            this.clearMap();
            if (this._enableCircuits) { this.startClustering(this.mapInstance, this.circuitsList, EALL_TRACKS.CIRCUIT); }
            if (this._enableEmotionTour) { this.startClustering(this.mapInstance, this.emotionTourList, EALL_TRACKS.EMOTION_TOUR); }
            if (this._enableOffTour) { this.startClustering(this.mapInstance, this.offRoadTourList, EALL_TRACKS.OFF_ROAD); }
          }
          break;
        case EALL_TRACKS.CIRCUIT:
          localStorage.setItem('filter', data.data);
          if (this.currentSearchTerm.length == 0) {
            this.clearMap();
            if (this._enableCircuits) { this.startClustering(this.mapInstance, this.circuitsList, EALL_TRACKS.CIRCUIT); }
          }
          break;
        case EALL_TRACKS.EMOTION_TOUR:
          localStorage.setItem('filter', data.data);
          if (this.currentSearchTerm.length == 0) {
            this.clearMap();
            if (this._enableEmotionTour) { this.startClustering(this.mapInstance, this.emotionTourList, EALL_TRACKS.EMOTION_TOUR); }
          }
          break;
        case EALL_TRACKS.OFF_ROAD:
          localStorage.setItem('filter', data.data);
          if (!this.currentSearchTerm) {
            this.clearMap();
            if (this._enableOffTour) { this.startClustering(this.mapInstance, this.offRoadTourList, EALL_TRACKS.OFF_ROAD); }
          }
          break;
        case EALL_TRACKS.FAVOURITE:          
          localStorage.setItem('filter', data.data);
          break;
        case EALL_TRACKS.MY_TRACKS:
          localStorage.setItem('filter', data.data);
          break;
        default:
          localStorage.setItem('filter', EALL_TRACKS.ALL);
          this.selectedFilter = EALL_TRACKS.ALL;
      }
    }


  }

  sendSearchDataToMap(event: any) {
    let passedData:any = {}
    this.currentSearchTerm = event.searchTerm;
    passedData.searchTrigger = true;
    this.searchMode = true;
    passedData.filterTrigger = false;
    passedData.data = event;
    this.updateDataOnMapAndList(passedData);
    
  }

  addDisplayClassForSummary() {
    this.initialize = true;
    let tourCover = document.getElementById('summaryPage');
    tourCover?.classList.add('details-page-class');
  }

  async removeIndexedDb() {
    const dbs = await window.indexedDB.databases()
    dbs.forEach((db: any) => { window.indexedDB.deleteDatabase(db.name) })
  }

  ngOnDestroy() {
    this.removeIndexedDb();
  }
}
