import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor, HttpErrorResponse } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { AuthenticationService } from '../services/auth-service/auth-service';
import { AuthAmplifyService } from '../services/auth-service/amplify-auth-service';

@Injectable({ providedIn: 'root' })
export class JwtInterceptor implements HttpInterceptor {
    constructor(private authAmplifyService: AuthAmplifyService, private authenticationService: AuthenticationService) { 
        console.log('JwtInterceptor') 
    }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        console.log('JwtInterceptor triggered');
        //add auth header with jwt if user is logged in and request is to the api url
        // const currentUser = this.authenticationService.currentUserValue;
        // const isLoggedIn = currentUser && currentUser.access_token;
        let currentUser = localStorage.getItem('currentCIAMUser');
        let isLoggedIn = false;
        if(currentUser) {
            const currentUserData = JSON.parse(currentUser);
            isLoggedIn = currentUser && currentUserData.access_token;
            const isApiUrl = (request.url.startsWith(environment.raceUrl) || request.url.startsWith(environment.newraceUrl));
            if (isLoggedIn && isApiUrl) {
                request = request.clone({
                    setHeaders: {
                        Authorization: `Bearer ${currentUserData.access_token}`
                    }
                });
            }            
        }
        return next.handle(request);
    }    
}