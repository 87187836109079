
<div class="emotional-data-animator-container float-left">
	<div [hidden]="sliceMarqueeData" class="banner">
		<div>
			<app-emotional-data [value]="position" [title]="'RSP_RANKING' | customTranslation: translatedArray" [unit]="''"></app-emotional-data>
		</div>
		<div>
			<app-emotional-data [value]="bestTime" [title]="'RSP_TIME' | customTranslation: translatedArray" [unit]="'Min'"></app-emotional-data>
		</div>
		<div>
			<app-emotional-data [value]="maxBrakeTorque" [title]="'RSP_MAX_BRAKE_TORQUE' | customTranslation: translatedArray" [unit]="'NM'">
			</app-emotional-data>
		</div>

		<div>
			<app-emotional-data [value]="maxLateralAcc" [title]="'RSP_MAX_LAT_ACC' | customTranslation: translatedArray" [unit]="'m/s²'">
			</app-emotional-data>
		</div>
		<div *ngIf="maxDriftAngle > 0">
			<app-emotional-data [value]="maxDriftAngle" [title]="'RSP_MAX_DRIFT_ANGLE' | customTranslation: translatedArray" [unit]="'°'"></app-emotional-data>
		</div>
		<div *ngIf="lapCount > 1">
			<app-emotional-data [value]="lapCount" [title]="'RSP_LAP_COUNT' | customTranslation: translatedArray" [unit]="''">
			</app-emotional-data>
		</div>
		<div>
			<app-emotional-data [value]="position" [title]="'RSP_RANKING' | customTranslation: translatedArray" [unit]="''"></app-emotional-data>
		</div>
		<div>
			<app-emotional-data [value]="bestTime" [title]="'RSP_TIME' | customTranslation: translatedArray" [unit]="'Min'"></app-emotional-data>
		</div>
		<div>
			<app-emotional-data [value]="maxBrakeTorque" [title]="'RSP_MAX_BRAKE_TORQUE' | customTranslation: translatedArray" [unit]="'NM'">
			</app-emotional-data>
		</div>
	</div>


	<div [hidden]="sliceMarqueeData" class="banner-right">
		<div>
			<app-emotional-data [value]="maxTransmissionOilTemp" [title]="'RSP_MAX_TRANS_OIL_TEMP' | customTranslation: translatedArray" [unit]="'C'">
			</app-emotional-data>
		</div>
		<div>
			<app-emotional-data [value]="topSpeed" [title]="'RSP_TOP_SPEED' | customTranslation: translatedArray" [unit]="'KM/H'"></app-emotional-data>
		</div>
		<div>
			<app-emotional-data [value]="maxGForce" [title]="'RSP_MAX_GFORCE' | customTranslation: translatedArray" [unit]="'G'"></app-emotional-data>
		</div>
		<div>
			<app-emotional-data [value]="maxTireTemp" [title]="'RSP_MAX_TIRE_TEMP' | customTranslation: translatedArray" [unit]="'c'">
			</app-emotional-data>
		</div>
		<div>
			<app-emotional-data [value]="maxLongitudalAcc" [title]="'RSP_MAX_LON_ACC' | customTranslation: translatedArray" [unit]="'m/s²'">
			</app-emotional-data>
		</div>
		<div>
			<app-emotional-data [value]="topSpeed" [title]="'RSP_TOP_SPEED' | customTranslation: translatedArray" [unit]="'KM/H'"></app-emotional-data>
		</div>
		<div>
			<app-emotional-data [value]="maxGForce" [title]="'RSP_MAX_GFORCE' | customTranslation: translatedArray" [unit]="'G'"></app-emotional-data>
		</div>
		<div>
			<app-emotional-data [value]="maxTireTemp" [title]="'RSP_MAX_TIRE_TEMP' | customTranslation: translatedArray" [unit]="'c'">
			</app-emotional-data>
		</div>
		<div>
			<app-emotional-data [value]="maxLongitudalAcc" [title]="'RSP_MAX_LON_ACC' | customTranslation: translatedArray" [unit]="'m/s²'">
			</app-emotional-data>
		</div>
	</div>

	<div [hidden]="!sliceMarqueeData" class="banner">
		<div>
			<app-emotional-data [value]="position" [title]="'RSP_RANKING' | customTranslation: translatedArray" [unit]="''"></app-emotional-data>
		</div>
		<div>
			<app-emotional-data [value]="bestTime" [title]="'RSP_TIME' | customTranslation: translatedArray" [unit]="'Min'"></app-emotional-data>
		</div>
		<div>
			<app-emotional-data [value]="maxBrakeTorque" [title]="'RSP_MAX_BRAKE_TORQUE' | customTranslation: translatedArray" [unit]="'NM'">
			</app-emotional-data>
		</div>	
		<div>
			<app-emotional-data [value]="position" [title]="'RSP_RANKING' | customTranslation: translatedArray" [unit]="''"></app-emotional-data>
		</div>
		<div>
			<app-emotional-data [value]="bestTime" [title]="'RSP_TIME' | customTranslation: translatedArray" [unit]="'Min'"></app-emotional-data>
		</div>	
	</div>

	<div [hidden]="!sliceMarqueeData" class="banner-right">
		<div>
			<app-emotional-data [value]="maxTransmissionOilTemp" [title]="'RSP_MAX_TRANS_OIL_TEMP' | customTranslation: translatedArray" [unit]="'C'">
			</app-emotional-data>
		</div>
		<div>
			<app-emotional-data [value]="topSpeed" [title]="'RSP_TOP_SPEED' | customTranslation: translatedArray" [unit]="'KM/H'"></app-emotional-data>
		</div>
		<div>
			<app-emotional-data [value]="maxGForce" [title]="'RSP_MAX_GFORCE' | customTranslation: translatedArray" [unit]="'G'"></app-emotional-data>
		</div>
		<div>
			<app-emotional-data [value]="maxTireTemp" [title]="'RSP_MAX_TIRE_TEMP' | customTranslation: translatedArray" [unit]="'c'">
			</app-emotional-data>
		</div>
		<div>
			<app-emotional-data [value]="maxLongitudalAcc" [title]="'RSP_MAX_LON_ACC' | customTranslation: translatedArray" [unit]="'m/s²'">
			</app-emotional-data>
		</div>
		<div>
			<app-emotional-data [value]="topSpeed" [title]="'RSP_TOP_SPEED' | customTranslation: translatedArray" [unit]="'KM/H'"></app-emotional-data>
		</div>
		<div>
			<app-emotional-data [value]="maxGForce" [title]="'RSP_MAX_GFORCE' | customTranslation: translatedArray" [unit]="'G'"></app-emotional-data>
		</div>
	</div>

	<div [hidden]="!sliceMarqueeData" class="banner">
		<div>
			<app-emotional-data [value]="maxLateralAcc" [title]="'RSP_MAX_LAT_ACC' | customTranslation: translatedArray" [unit]="'m/s²'">
			</app-emotional-data>
		</div>
		
		<div *ngIf="lapCount > 1">
			<app-emotional-data [value]="lapCount" [title]="'RSP_LAP_COUNT' | customTranslation: translatedArray" [unit]="''">
			</app-emotional-data>
		</div>
		<div>
			<app-emotional-data [value]="maxTireTemp" [title]="'RSP_MAX_TIRE_TEMP' | customTranslation: translatedArray" [unit]="'c'">
			</app-emotional-data>
		</div>
		<div *ngIf="maxDriftAngle > 0">
			<app-emotional-data [value]="maxDriftAngle" [title]="'RSP_MAX_DRIFT_ANGLE' | customTranslation: translatedArray" [unit]="'°'"></app-emotional-data>
		</div>
		<div>
			<app-emotional-data [value]="maxLateralAcc" [title]="'RSP_MAX_LAT_ACC' | customTranslation: translatedArray" [unit]="'m/s²'">
			</app-emotional-data>
		</div>
		<div>
			<app-emotional-data [value]="maxLongitudalAcc" [title]="'RSP_MAX_LON_ACC' | customTranslation: translatedArray" [unit]="'m/s²'">
			</app-emotional-data>
		</div>
		<div *ngIf="maxDriftAngle > 0">
			<app-emotional-data [value]="maxDriftAngle" [title]="'RSP_MAX_DRIFT_ANGLE' | customTranslation: translatedArray" [unit]="'°'" ></app-emotional-data>
		</div>
	</div>


</div>
